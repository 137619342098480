import type { MaybeRef } from 'vue'
import type { ISbStoryData } from '@storyblok/js'

interface StoryblokContentBase<T extends string> {
  _uid?: string
  component?: T
  _editable?: string
  simple_layout_button_text: string
  layout: 'simple' | 'default' | 'blank'
  nav_color: {
    value: string
  }
  footer_color: {
    value: string
  }
  [index: string]: any
}
type StoryblokData<T> = ISbStoryData<StoryblokContentBase<string> & T>

export const useLayout = <T>(storyRef: MaybeRef<StoryblokData<T>>) => {
  const story = unref(storyRef)
  const { t } = useI18n()
  const layoutName = computed(() => story.content.layout || 'default')
  const layoutButtonText = computed(
    () => story.content.simple_layout_button_text || t('common.navigation.cta'),
  )
  const layoutNavbarColor = computed(
    () => story.content.nav_color?.value || '#fff',
  )
  const layoutFooterColor = computed(
    () => story.content.footer_color?.value || '#fff',
  )

  return {
    layoutName,
    layoutNavbarColor,
    layoutButtonText,
    layoutFooterColor
  }
}
